import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import ServiceDetailsContent from "../components/ServiceDetails/ServiceDetailsContent"
import RelatedServices from "../components/ServiceDetails/RelatedServices"
import WordpressImage from "../assets/images/services/WordPress.png"

const Wordpress = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Wordpress"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Wordpress"
      />
      <ServiceDetailsContent
        aboutService="WordPress is a Simplest but most powerful and popular tool. You can easily manage your site, even if you dont have any IT experience"
        technologies={["JavaScript", "HTML", "Git", "CSS", "SQL"]}
        detailImage={WordpressImage}
      />
      {/* <RelatedServices /> */}
      <Footer />
    </Layout>
  )
}

export default Wordpress
